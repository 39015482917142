import { FILTERS_EQUAL, STERILIZATION_AUDITS_CONTAINERSIZE } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Container Size", "search-by": "Facility,Description,Type,Name", "ph-search-by": "Search by Container Type, Container Size, Nominal Description" } }, [_c("text-field", { key: "Type", attrs: { "data-index": "type", "title": "Container Type", "sorter": true } }), _c("text-field", { key: "Name", attrs: { "data-index": "name", "title": "Container Size", "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Nominal Description", "sorter": true } }), _c("text-field", { key: "FillingLines", attrs: { "data-index": "fillingLines", "title": "Line", "parse": _vm.parseDataLine, "sorter": false } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListContainerSize",
  inject: ["crud"],
  created() {
    this.crud.setPagination({ page: 1, pageSize: 10 });
    this.crud.setFilter("IsInactive", {
      operator: FILTERS_EQUAL,
      value: "false"
    });
  },
  methods: {
    parseDataLine(lines) {
      if (lines && lines.length) {
        const lineNames = [...lines].map((item) => item.description);
        return lineNames.join(", ");
      }
      return "";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListContainerSize = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-container-size" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "sterilization.common.container-types", "api-url": _vm.apiUrl, "resource-id-name": "name" } }), _c("resource", { attrs: { "name": "sterilization.common.filling-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.can-sizes", "api-url": _vm.apiUrl, "create-route": "/sterilization-audits/container-size/create", "edit-route": "/sterilization-audits/container-size/:id", "list": _vm.ListContainerSize, "page": _vm.page } }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "ListContainerSize",
  data() {
    return {
      page: STERILIZATION_AUDITS_CONTAINERSIZE,
      ListContainerSize,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "container-size",
          title: "Container Size",
          path: "/sterilization-audits/container-size"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
